import { ImmerStateCreator, Store } from '@/store/type'
import { AppConfigSlice, AppConfigState } from '@/store/appConfigSlice/type'

const initialState: AppConfigState = {
    stdCostTablesVisibleState: {},
    isSidenavOpen: false,
    isMobile: window.innerWidth < 640,
    isChangePasswordDialogOpen: false,
}

export const useAppConfigSlice: ImmerStateCreator<AppConfigSlice> = (set) => ({
    state: initialState,
    actions: {
        setSidenavOpen: (isOpen) =>
            set((state) => {
                state.appConfigSlice.state.isSidenavOpen = isOpen
            }),
        setIsMoble: (isMobile) =>
            set((state) => {
                state.appConfigSlice.state.isMobile = isMobile
            }),
        onChangePasswordDialogClose: () =>
            set((state) => {
                state.appConfigSlice.state.isChangePasswordDialogOpen = false
            }),
        onChangePasswordDialogOpen: () =>
            set((state) => {
                state.appConfigSlice.state.isChangePasswordDialogOpen = true
            }),
        onReset: () =>
            set((state) => {
                state.appConfigSlice.state = initialState
            }),
    },
})

export const appConfigStateSelector = (state: Store) =>
    state.appConfigSlice.state

export const appConfigActionsSelector = (state: Store) =>
    state.appConfigSlice.actions
