import { fetchForm } from '@/api/business/form'
import { useQuery } from '@tanstack/react-query'

export const formKeys = {
    all: ['form'] as const,
    lists: () => [...formKeys.all, 'list'] as const,
}

type QueryCommonProps = {
    enabled: boolean
}

export const useFormQuery = ({ enabled = true }: QueryCommonProps) => {
    return useQuery({
        queryFn: fetchForm,
        queryKey: formKeys.lists(),
        enabled,
        staleTime: Infinity,
        select: (data) => {
            return data
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((section) => ({
                    ...section,
                    fields: section.fields
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((field) => ({
                            ...field,
                            options: field.options?.sort((a, b) =>
                                a.order > b.order ? 1 : -1
                            ),
                        })),
                }))
        },
    })
}
