import Container from '@/components/Container'
import { Button } from '@/components/ui/button'
import { TypographyLarge, TypographyMuted } from '@/components/ui/typography'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <Container className="mt-[8rem] text-center gap-8 items-center">
            <div className="flex flex-col items-center justify-center">
                <TypographyLarge className="text-[8rem] font-bold text-primary">
                    404
                </TypographyLarge>
                <TypographyMuted>
                    A página ou recurso solicitado não foi encontrado
                </TypographyMuted>
            </div>
            <Button size="lg" asChild>
                <Link to="/" replace>
                    Voltar ao início
                </Link>
            </Button>
        </Container>
    )
}

export default NotFound
