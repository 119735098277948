import instance from '..'
import { getData, Response } from '@/api/utils'
import { AnswerFormProps, FormSection } from './types'

const BASE_URL = '/formulario'

export const fetchForm = async () => {
    const response = await instance.get<Response<FormSection[]>>(BASE_URL)

    return getData(response.data)
}

export const answerForm = async (props: AnswerFormProps) => {
    const response = await instance.post(BASE_URL, props)

    return getData(response.data)
}
