import Container from '@/components/Container'
import { Button } from '@/components/ui/button'
import { TypographyLarge, TypographyMuted } from '@/components/ui/typography'
import { Link } from 'react-router-dom'

const Unauthorized = () => {
    return (
        <Container className="mt-[8rem] text-center gap-8 items-center">
            <div className="flex flex-col items-center justify-center gap-4">
                <div>
                    <TypographyLarge className="text-[8rem] font-bold text-primary">
                        401
                    </TypographyLarge>
                    <TypographyMuted className="text-xl">
                        Token de autorização inválido
                    </TypographyMuted>
                </div>
                <TypographyMuted>
                    É necessário estar autenticado para acessar essa página
                </TypographyMuted>
            </div>
            <Button size="lg" asChild>
                <Link to="/login" replace>
                    Ir para login
                </Link>
            </Button>
        </Container>
    )
}

export default Unauthorized
