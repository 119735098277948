import { FormSection } from '@/api/business/form/types'
import { Slice } from '../type'

export enum FieldType {
    options = 'options',
    value = 'value',
}

type PrevNextField = {
    sectionId: number | undefined
    fieldId: number | undefined
}

type FieldStateCommonProps = {
    isRequired: boolean
    fieldId: number
    prevField: PrevNextField
    nextField: PrevNextField
}

export type FieldValue =
    | {
          type: FieldType.options
          value: number | number[] | undefined
      }
    | {
          type: FieldType.value
          value: string | number | undefined
      }
export type FieldStateData = FieldValue & FieldStateCommonProps
export type FieldState = Record<number, FieldStateData>
export type SectionState = Record<
    number,
    { sectionId: number; fieldState: FieldState }
>

export type User = {
    userRegistration: string
    isValid: boolean
}

export type FormState = {
    formState: SectionState
    initialFormState: SectionState
    shouldValidate: boolean
    user: User
}

export type SetFieldValueFn = (
    sectionId: number,
    fieldId: number,
    value: FieldValue
) => void

export type FormActions = {
    setInitialValues: (form: FormSection[]) => void
    setFieldValue: SetFieldValueFn
    setShouldValidate: (shouldValidate: boolean) => void
    setUser: (userRegistration: string) => void
    setIsUserValid: (isValid: boolean) => void
}

export type FormSlice = Slice<FormState, FormActions>
