export const minutesToHours = (minutes: number) => {
    if (typeof minutes !== 'number' || minutes < 0) {
        return ''
    }

    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    const hoursText = hours > 0 ? `${hours}h` : ''
    const minutesText = remainingMinutes > 0 ? `${remainingMinutes}min` : ''

    return hoursText + (hoursText && minutesText ? ' ' : '') + minutesText
}

export const getInitials = (name: string) => {
    const nameArray = name.split(' ')

    const [firstName, lastName] = [
        nameArray[0],
        nameArray[nameArray.length - 1],
    ]

    return nameArray.length > 1 ? `${firstName[0]}${lastName[0]}` : firstName[0]
}

export const percentageFormat = (percentage: number, decimals = 2): string => {
    return Intl.NumberFormat('pt-br', {
        style: 'percent',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(percentage / 100)
}

export const numberFormat = (value: number | string, decimals = 2) => {
    const number = typeof value === 'string' ? Number(value) : value
    const newValue = Intl.NumberFormat('pt-br', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(number)

    return newValue
}

export const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}
