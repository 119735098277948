import { useBaseStore } from '@/store'
import { FieldState, SetFieldValueFn } from '@/store/formSlice/type'
import { Store } from '@/store/type'
import { createContext, useContext } from 'react'

type FormSectionContextState = {
    sectionId: number
    sectionFieldState?: FieldState
    shouldValidate: boolean
    setFieldValue: SetFieldValueFn
}

const FormSectionContext = createContext<FormSectionContextState>(
    {} as FormSectionContextState
)

type FormSectionContextProviderProps = {
    children: React.ReactNode
    sectionId: number
}

const stateSelector = (sectionId: number) => (state: Store) => ({
    sectionFieldState: state.formSlice.state.formState[sectionId]?.fieldState,
    shouldValidate: state.formSlice.state.shouldValidate,
    setFieldValue: state.formSlice.actions.setFieldValue,
})

export const FormSectionContextProvider = ({
    children,
    sectionId,
}: FormSectionContextProviderProps) => {
    const { sectionFieldState, shouldValidate, setFieldValue } = useBaseStore(
        stateSelector(sectionId)
    )

    return (
        <FormSectionContext.Provider
            value={{
                sectionId,
                shouldValidate,
                sectionFieldState,
                setFieldValue,
            }}
        >
            {children}
        </FormSectionContext.Provider>
    )
}

export const useFormSectionContext = () => useContext(FormSectionContext)
