import Router from './Router'
import { ThemeProvider } from './context/ThemeProvider'
import { useCheckMobile } from './hooks/useCheckMobile'

function App() {
    useCheckMobile()

    return (
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
            <Router />
        </ThemeProvider>
    )
}

export default App
