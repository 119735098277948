import instance from '..'
import { getData, Response } from '@/api/utils'
import { FetchUserByRegistrationProps, SeculumUser } from './types'

const BASE_URL = '/usuario'

export const fetchUserByRegistration = async ({
    userRegistration,
}: FetchUserByRegistrationProps) => {
    const response = await instance.get<Response<SeculumUser>>(
        BASE_URL + '/' + userRegistration
    )

    return getData(response.data)
}
