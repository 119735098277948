import { useState } from 'react'
import { Eye, EyeOff } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useBaseStore } from '@/store'
import { useMutation } from '@tanstack/react-query'
import { authenticateUser } from '@/api/auth/user'
import { Button } from '@/components/ui/button'
import { decodeJwt } from 'jose'
import { Token } from '@/api/auth/user/type'
import { Store } from '@/store/type'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { TypographyH3, TypographyMuted } from '@/components/ui/typography'
import { Input } from '@/components/ui/input'
import { getError } from '@/utils/getError'
import { toast } from 'sonner'

const stateSelector = (state: Store) => ({
    login: state.authSlice.actions.login,
})

const loginSchema = z.object({
    username: z.string().min(1, 'Campo obrigatório'),
    password: z.string().min(1, 'Campo obrigatório'),
})

type LoginSchemaType = z.infer<typeof loginSchema>

const Login = () => {
    const [passwordVisible, setPasswordVisible] = useState(false)

    const { state } = useLocation()
    const navigate = useNavigate()

    const { login } = useBaseStore(stateSelector)

    const form = useForm<LoginSchemaType>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            password: '',
            username: '',
        },
    })

    const { mutate: onLogin, isPending } = useMutation({
        mutationFn: authenticateUser,
        onSuccess: (data) => {
            const decoded = decodeJwt(data.token) as Token

            login({
                token: data.token,
                user: form.getValues('username'),
                userId: decoded.usuario.cod_usuario,
                userName: decoded.usuario.nome,
            })

            navigate(state?.path || '/')
        },
        onError: (err) => {
            const message = getError(err, 'Usuário ou senha incorretos')

            toast.error('Erro ao fazer login', {
                description: message,
            })
        },
    })

    const onSubmit = async (data: LoginSchemaType) => {
        onLogin({ user: data.username, password: data.password })
    }

    return (
        <Form {...form}>
            <div className="flex items-center justify-center min-h-screen bg-background">
                <form
                    className="w-full max-w-md p-8 space-y-4 bg-white rounded-md shadow-lg"
                    onSubmit={form.handleSubmit(onSubmit)}
                >
                    <div className="text-center">
                        <TypographyH3>Satisfacil</TypographyH3>
                    </div>
                    <FormField
                        name="username"
                        control={form.control}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel htmlFor="username">
                                    Usuário
                                </FormLabel>
                                <FormControl>
                                    <Input id="username" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        name="password"
                        control={form.control}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel htmlFor="password">Senha</FormLabel>
                                <FormControl>
                                    <div className="flex items-center gap-2 pr-2 border rounded-md border-input">
                                        <Input
                                            id="password"
                                            className="border-none focus-visible:ring-0"
                                            {...field}
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                setPasswordVisible(
                                                    (prev) => !prev
                                                )
                                            }
                                            className="focus:outline-none"
                                            aria-label={
                                                passwordVisible
                                                    ? 'Ocultar senha'
                                                    : 'Mostrar senha'
                                            }
                                        >
                                            {passwordVisible ? (
                                                <EyeOff
                                                    className="cursor-pointer hover:text-neutral-500"
                                                    size={18}
                                                />
                                            ) : (
                                                <Eye
                                                    className="cursor-pointer hover:text-neutral-500"
                                                    size={18}
                                                />
                                            )}
                                        </button>
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="mt-4 text-right">
                        <TypographyMuted asChild className="hover:underline">
                            <Link to="/recuperar-senha">Recuperar senha</Link>
                        </TypographyMuted>
                    </div>
                    <Button
                        className="w-full mt-6"
                        disabled={isPending}
                        type="submit"
                    >
                        Entrar
                    </Button>
                </form>
            </div>
        </Form>
    )
}

export default Login
