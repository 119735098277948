import { cn } from '@/lib/utils'
import { Slot } from '@radix-ui/react-slot'
import { HTMLAttributes } from 'react'

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
    asChild?: boolean
}

const Container = ({
    className,
    asChild,
    children,
    ...props
}: ContainerProps) => {
    const Comp = asChild ? Slot : 'div'

    return (
        <Comp className={'flex flex-col flex-1 overflow-auto'} {...props}>
            <div className={cn('flex flex-col h-full md:container', className)}>
                {children}
            </div>
        </Comp>
    )
}

export default Container
