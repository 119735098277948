import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { useAuthSlice } from './authSlice'
import { useResetSlice } from './resetSlice'
import { useAppConfigSlice } from './appConfigSlice'
import { immer } from 'zustand/middleware/immer'
import { Store } from './type'
import { useFormSlice } from './formSlice'

export const useBaseStore = createWithEqualityFn<Store>()(
    immer((...a) => ({
        authSlice: useAuthSlice(...a),
        resetSlice: useResetSlice(...a),
        appConfigSlice: useAppConfigSlice(...a),
        formSlice: useFormSlice(...a),
    })),
    shallow
)
