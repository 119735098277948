import { fetchUserByRegistration } from '@/api/business/seculumUser'
import { FetchUserByRegistrationProps } from '@/api/business/seculumUser/types'
import { useQuery } from '@tanstack/react-query'

export const userKeys = {
    all: ['user'] as const,
    detail: (id: string) => [...userKeys.all, id] as const,
}

type QueryCommonProps = {
    enabled: boolean
}

type UseUserQueryProps = FetchUserByRegistrationProps & QueryCommonProps

export const useUserQuery = ({
    enabled = true,
    userRegistration,
}: UseUserQueryProps) => {
    return useQuery({
        queryFn: () => fetchUserByRegistration({ userRegistration }),
        queryKey: userKeys.detail(userRegistration),
        enabled,
        retry: false,
    })
}
