import { isAxiosError } from '@/api/business'
import { ReactNode } from 'react'
import Forbidden from '@/pages/Forbidden'
import Unauthorized from '@/pages/Unauthorized'
import Error from '@/pages/Error'
import NotFound from '@/pages/NotFound'

type PageLoaderProps = {
    children: ReactNode
    fallback: ReactNode
    isLoading: boolean
    error?: Error | null
}

const errorFallback = {
    401: <Unauthorized />,
    403: <Forbidden />,
    404: <NotFound />,
}

const PageLoader = ({
    children,
    fallback,
    error,
    isLoading,
}: PageLoaderProps) => {
    if (isLoading) return fallback

    if (error && isAxiosError(error)) {
        if (!error.response?.status) return <Error />

        return errorFallback[error.response.status]
    }

    return <>{children}</>
}

export default PageLoader
