import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '@/pages/Login'
import NotFound from '@/pages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import ResetPassword from '@/pages/ResetPassword'
import Home from '@/pages/Home'
import MainLayout from '@/pages/Layouts/MainLayout'
import RequireAuth from '@/middlewares/RequireAuth'

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/recuperar-senha"
                        element={<ResetPassword />}
                    />
                </Route>
                <Route element={<MainLayout />}>
                    <Route path="/">
                        <Route
                            element={
                                <RequireAuth>
                                    <Home />
                                </RequireAuth>
                            }
                            index
                        />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
