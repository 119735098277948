import { authenticateUser } from '@/api/auth/user'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { getError } from '@/utils/getError'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

type LogoutDialogProps = DisclosureCommonProps

const stateSelector = (state: Store) => ({
    user: state.authSlice.state.user,
    logout: state.authSlice.actions.logout,
})

const logoutSchema = z.object({
    password: z.string().min(1, 'Campo obrigatório'),
})

type LogoutSchemaType = z.infer<typeof logoutSchema>

const LogoutDialog = ({ isOpen, onOpenChange }: LogoutDialogProps) => {
    const { user, logout } = useBaseStore(stateSelector)
    const queryClient = useQueryClient()

    const { mutate: onLogout, isPending } = useMutation({
        mutationFn: authenticateUser,
        onSuccess: () => {
            logout(queryClient)
        },
        onError: (err) => {
            const message = getError(err, 'Usuário ou senha incorretos')

            toast.error('Erro ao fazer logoff', {
                description: message,
            })
        },
    })

    const form = useForm<LogoutSchemaType>({
        resolver: zodResolver(logoutSchema),
        defaultValues: {
            password: '',
        },
    })

    const onSubmit = async (data: LogoutSchemaType) => {
        onLogout({ user: user as string, password: data.password })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="bg-white">
                <DialogHeader>
                    <DialogTitle>Sair</DialogTitle>
                    <DialogDescription>
                        Preencha o campo abaixo com a senha para fazer o logoff
                        da aplicação
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="password"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="password">
                                        Senha
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            id="password"
                                            {...field}
                                            type="password"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() => onOpenChange(false)}
                            >
                                Cancelar
                            </Button>
                            <Button disabled={isPending} type="submit">
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default LogoutDialog
