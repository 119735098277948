import { setItem } from '@/utils/storage'
import { ImmerStateCreator, Store } from '@/store/type'
import { AuthSlice, AuthStore } from './type'
import instance from '@/api/business'
import { AxiosError } from 'axios'

const initialState: AuthStore = {
    token: null,
    user: null,
    userId: null,
    tokenExpired: false,
    userName: null,
}

export const useAuthSlice: ImmerStateCreator<AuthSlice> = (set) => ({
    state: initialState,
    actions: {
        login: (user) => {
            set((state) => {
                state.authSlice.state = {
                    user: user.user,
                    token: user.token,
                    userId: user.userId,
                    userName: user.userName,
                    tokenExpired: false,
                }
            })

            setItem(localStorage, 'token', user.token)
            setItem(localStorage, 'user', user.user)

            instance.interceptors.response.use(
                (onSuccess) => onSuccess,
                (onError: AxiosError) => {
                    if (onError.response?.status === 401)
                        set((state) => {
                            state.authSlice.state.tokenExpired = true
                        })

                    throw onError
                }
            )
        },
        logout: (queryClient) => {
            set((state) => {
                state.authSlice.state = initialState
            })
            localStorage.clear()
            queryClient.clear()
        },
        resetAuthState: () =>
            set((state) => {
                state.authSlice.state = initialState
            }),
        onReset: () =>
            set((state) => {
                state.authSlice.state = initialState
            }),
    },
})

export const authStateSelector = (state: Store) => state.authSlice.state
export const authActionsSelector = (state: Store) => state.authSlice.actions
