import { FormField } from '@/api/business/form/types'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { useFormSectionContext } from '../../context/FormSectionContext'
import { useEffect, useMemo, useState } from 'react'
import { FieldType } from '@/store/formSlice/type'
import { cn } from '@/lib/utils'

type FieldRadioProps = {
    formField: FormField
}

const FieldRadio = ({ formField }: FieldRadioProps) => {
    const { sectionId, shouldValidate, sectionFieldState, setFieldValue } =
        useFormSectionContext()

    const value = useMemo(
        () => sectionFieldState && sectionFieldState[formField.id],
        [sectionFieldState, formField]
    )

    const [selectedRadio, setSelectedRadio] = useState(
        (value?.value?.toString() as string) || ''
    )

    let isValid = true

    if (value?.isRequired) {
        isValid = (value?.value as number[])?.length > 0
    }

    const isError = shouldValidate && !isValid

    useEffect(() => {
        if (Array.isArray(value?.value)) {
            setSelectedRadio(value?.value[0]?.toString() as string)
        }

        if (!value?.value) {
            setSelectedRadio('')
        }
    }, [value])

    return (
        <Card
            className={cn(
                'border-white rounded-bl-none rounded-br-none',
                isError && 'border-destructive '
            )}
        >
            <CardHeader className="p-8 border-b">
                <CardTitle
                    className={cn(
                        'items-center gap-2 md:text-xl',
                        isError ? 'text-destructive' : ''
                    )}
                >
                    {formField.label}
                    {formField.isRequired && (
                        <span className="text-red-500"> *</span>
                    )}
                </CardTitle>
                <CardDescription className="md:text-lg">
                    Selecione uma opção
                </CardDescription>
            </CardHeader>
            <CardContent className="p-4">
                <RadioGroup
                    className="gap-0"
                    value={selectedRadio}
                    onValueChange={(value) => {
                        setSelectedRadio(value)
                        setFieldValue(sectionId, formField.id, {
                            type: FieldType.options,
                            value: [Number(value)],
                        })
                    }}
                >
                    {formField.options.map((option) => {
                        const isSelected =
                            selectedRadio === option.id.toString()

                        return (
                            <div
                                key={`${sectionId}${formField.id}${option.id}`}
                                className={cn(
                                    'flex items-center gap-2 rounded-lg px-4 cursor-pointer',
                                    isSelected && 'bg-accent '
                                )}
                            >
                                <RadioGroupItem
                                    id={option.id.toString()}
                                    value={option.id.toString()}
                                />
                                <Label
                                    htmlFor={option.id.toString()}
                                    className={cn(
                                        'flex-1 py-4 md:text-lg',
                                        isSelected && 'text-accent-foreground'
                                    )}
                                >
                                    {option.label}
                                </Label>
                            </div>
                        )
                    })}
                </RadioGroup>
            </CardContent>
        </Card>
    )
}

export default FieldRadio
