import { Button } from '@/components/ui/button'
import { useCarousel } from '@/components/ui/carousel'

const CarouselNext = () => {
    const { scrollNext, canScrollNext } = useCarousel()

    return (
        <Button
            disabled={!canScrollNext}
            onClick={scrollNext}
            className="flex-1 md:flex-0"
        >
            Próximo
        </Button>
    )
}

export default CarouselNext
