import {
    Ban,
    CircleCheckBig,
    Info,
    LoaderCircle,
    TriangleAlert,
} from 'lucide-react'
import { useTheme } from 'next-themes'
import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme = 'system' } = useTheme()

    return (
        <Sonner
            theme={theme as ToasterProps['theme']}
            position="top-center"
            className="toaster group"
            toastOptions={{
                classNames: {
                    toast: 'group toast group-[.toaster]:bg-white group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg group-[.toaster]:pr-8',
                    description: 'group-[.toast]:text-muted-foreground',
                    actionButton:
                        'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
                    closeButton:
                        '[&&]:right-0 [&&]:left-auto [&&]:top-4 [&&]:border-none',
                },
                closeButton: true,
            }}
            icons={{
                error: <Ban size={16} className="text-destructive" />,
                info: <Info size={16} className="text-sky-600" />,
                loading: (
                    <LoaderCircle
                        size={16}
                        className="text-sky-600 animate-spin"
                    />
                ),
                success: (
                    <CircleCheckBig size={16} className="text-emerald-600" />
                ),
                warning: <TriangleAlert size={16} className="text-amber-600" />,
            }}
            {...props}
        />
    )
}

export { Toaster }
