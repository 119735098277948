import FormHeader from './components/FormHeader'
import Form from './components/Form'

const Home = () => {
    return (
        <>
            <FormHeader />
            <Form />
        </>
    )
}

export default Home
