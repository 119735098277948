import { DisclosureCommonProps } from '@/types/Disclosure'
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet'
import { Avatar, AvatarFallback } from '../ui/avatar'
import { TypographyP } from '../ui/typography'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { getInitials } from '@/utils/stringFormatter'
import { Separator } from '../ui/separator'
import { LogOut } from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import LogoutDialog from './components/LogoutDialog'

interface SidebarProps extends DisclosureCommonProps {}

const stateSelector = (state: Store) => ({
    user: state.authSlice.state.userName,
})

const Sidebar = ({ isOpen, onOpenChange }: SidebarProps) => {
    const {
        isOpen: isLogoutDialogOpen,
        onOpenChange: onLogoutDialogOpenChange,
    } = useDisclosure()

    const { user } = useBaseStore(stateSelector)

    return (
        <>
            <Sheet open={isOpen} onOpenChange={onOpenChange}>
                <SheetTrigger asChild>
                    <Avatar className="w-8 h-8 md:h-10 md:w-10">
                        <AvatarFallback className="text-xs">
                            {getInitials(user || '')}
                        </AvatarFallback>
                    </Avatar>
                </SheetTrigger>
                <SheetContent className="flex flex-col p-0 bg-white">
                    <div className="flex flex-col justify-center items-center w-full gap-2 min-h-[200px] mx-auto">
                        <Avatar className="w-[80px] h-[80px]">
                            <AvatarFallback className="text-lg">
                                {getInitials(user || '')}
                            </AvatarFallback>
                        </Avatar>
                        <TypographyP>{user}</TypographyP>
                    </div>
                    <div className="flex-1"></div>
                    <div>
                        <Separator />
                        <div
                            className="flex items-center justify-center gap-2 p-4 cursor-pointer"
                            onClick={() => onLogoutDialogOpenChange(true)}
                        >
                            <LogOut size={14} />
                            <TypographyP>Sair</TypographyP>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
            <LogoutDialog
                isOpen={isLogoutDialogOpen}
                onOpenChange={onLogoutDialogOpenChange}
            />
        </>
    )
}

export default Sidebar
