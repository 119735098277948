import Lottie from 'lottie-react'
import Truck from '@/assets/lottie/truck.json'

const LoadingScreen = () => {
    return (
        <div className="flex items-center justify-center w-screen h-screen">
            <Lottie animationData={Truck} className="md:w-1/4" />
        </div>
    )
}

export default LoadingScreen
