import { Button } from '@/components/ui/button'
import { useCarousel } from '@/components/ui/carousel'

const CarouselPrevious = () => {
    const { scrollPrev, canScrollPrev } = useCarousel()

    return (
        <Button
            variant="ghost"
            disabled={!canScrollPrev}
            onClick={scrollPrev}
            className="flex-1 md:flex-0"
        >
            Voltar
        </Button>
    )
}

export default CarouselPrevious
