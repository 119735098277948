import { ImmerStateCreator } from '@/store/type'
import { ResetSlice } from './type'

export const useResetSlice: ImmerStateCreator<ResetSlice> = (_, get) => ({
    resetAll: () => {
        for (const key of Object.keys(get())) {
            get()[key]?.actions?.onReset()
        }
    },
})
