import {
    FieldType,
    FormField as FormFieldType,
} from '@/api/business/form/types'
import { ReactNode } from 'react'
import FieldCheckbox from '../FieldCheckbox'
import FieldRadio from '../FieldRadio'
import FieldNumber from '../FieldNumber'
import FieldText from '../FieldText'

type FormFieldProps = {
    formField: FormFieldType
}

const FormField = ({ formField }: FormFieldProps) => {
    const fieldComponent: Record<FieldType, ReactNode> = {
        checkbox: <FieldCheckbox formField={formField} />,
        radio: <FieldRadio formField={formField} />,
        number: <FieldNumber formField={formField} />,
        text: <FieldText formField={formField} />,
    }

    return fieldComponent[formField.type]
}

export default FormField
