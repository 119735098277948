import { isAxiosError } from '@/api/business'

const getError = (error: Error, defaultMessage: string) => {
    if (isAxiosError(error)) {
        return error.response?.data?.error || defaultMessage
    }

    return defaultMessage
}

export { getError }
