import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useEffect } from 'react'

const stateSelector = (state: Store) => ({
    setIsMobile: state.appConfigSlice.actions.setIsMoble,
})

export const useCheckMobile = () => {
    const { setIsMobile } = useBaseStore(stateSelector)

    useEffect(() => {
        const updateIsMobile = () => {
            const screenSize = window.innerWidth
            const isMobileDevice = screenSize < 640

            setIsMobile(isMobileDevice)
        }

        updateIsMobile()

        window.addEventListener('resize', updateIsMobile)

        return () => window.removeEventListener('resize', updateIsMobile)
    }, [setIsMobile])
}
